import { GetStaticPropsResult } from 'next'

import { ReactElement } from 'react'
import { ChevronForwardOutline, AlertCircleOutline } from 'react-ionicons'
import { PageMeta } from '@/src/pages'
import custom404PageData from '@/content/404.json'
import { Layout } from '@/components/Layout'
import { Button } from '@/components/elements/Button'
import { IonIcon } from '@/src/icons'
import { Container } from '@/components/utils'

interface Custom404PageProps {
  meta: PageMeta
  cta: {
    ctaUrl?: string
    ctaLabel?: string
  }
}

export async function getStaticProps(): Promise<
  GetStaticPropsResult<Custom404PageProps>
> {
  return {
    props: {
      meta: {
        title: custom404PageData.title,
        description: custom404PageData.description,
      },
      cta: custom404PageData.cta,
    },
  }
}

export default function Custom404Page({
  meta,
  cta,
}: Custom404PageProps): ReactElement {
  return (
    <Layout meta={meta}>
      <div className="py-12 bg-navy-light-100 md:py-32">
        <Container narrow>
          <div className="flex flex-col items-center">
            <IonIcon className="w-16 h-16" icon={AlertCircleOutline} />
            <h1 className="mt-6 text-center text-5xl font-black leading-none md:text-7xl">
              {meta.title}
            </h1>
            <p className="mt-10 text-center font-body text-lg">
              {meta.description}
            </p>
            <Button
              href={cta.ctaUrl}
              className="mt-16"
              variant="default"
              label={cta.ctaLabel}
              icon={<IonIcon icon={ChevronForwardOutline} />}
            />
          </div>
        </Container>
      </div>
    </Layout>
  )
}
